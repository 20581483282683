import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { useGlitch } from 'react-powerglitch'

const Home = (props: any) => {
    const controller = useController();
    const glitch = useGlitch();

    return (
        <S.mainContainer>
            <S.container>
                <S.imgLogo ref={glitch.ref} src='./app/images/logo/logo_arezdot_yellow_noname.png' />
                <S.mainText ref={glitch.ref}>Arezdot <br/> [EM BREVE]</S.mainText>
            </S.container>
        </S.mainContainer>
    )
}

export default Home;