import styled from 'styled-components';

export const mainContainer = styled.div`
    width: 100vw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    display: grid;
    background: black;
`;

export const container = styled.div`
    width: 100%;
    height: 50%;
    place-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -100px;

    @media(max-width: 768px){
        margin-top: -50px;
    }
`;
export const imgLogo = styled.img`
    width: 350px;
    object-fit: cover;
    align-self: center;
    justify-self: center;

    @media(max-width: 425px){
        width: 250px;
    }
`;
export const mainText = styled.h1`
    width: 100%;
    text-align: center;
    color: #E6CF00;
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-transform: uppercase;

    @media(max-width: 768px){
        font-size: 35px;
    }

    @media(max-width: 425px){
        font-size: 25px;
    }
`;