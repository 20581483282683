import api from "./utils/api";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

export default [
    {
        path: "*",
        Component: NotFound,
    },
    {
        path: "/",
        Component: Home,
        exact: true
    }
]